
// Author's custom styles
// we need to import this first so that it would override bootstrap scss variables later
@import "variables";

/* some default styles go in textstyles.css so the editor looks more like the page.*/
/* ******************************************************************** */
/* jquery validate 														 */
/* ******************************************************************** */
.error {
	color:red;
}
/* ******************************************************************** */
/* Clearfix: http://csscreator.com/attributes/containedfloat.php        */
/* ******************************************************************** */
.clearfix:after {
 content: "."; 
 display: block; 
height: 0; 
 clear: both; 
 visibility: hidden;
 }
 
.clearfix{display: inline-block;}

/* Hides from IE-mac \*/
* html .clearfix{height: 1%;}
.clearfix{display: block;}
/* End hide from IE-mac */  

/* ******************************************************************** */
/* Common elements 														*/
/* ******************************************************************** */
.contentwrap {
	width:960px;
	max-width:100%;
	margin:0 auto;
	position:relative;
	box-sizing:border-box;
}
body,html{
	overflow-x:hidden;
}
.left-bleed {
	margin-left:-3000px;
	padding-left:3000px;
}
.right-bleed {
	margin-right:-3000px;
	padding-right:3000px;
}
.justify {
	text-align:justify;
}
.justify > * {
	text-align:left;
}
.justify:after {
	content:"";
	display:inline-block;
	width:100%;
	height:0;
}
#content {
	padding-bottom:40px;
}
#content img {
	max-width:100% !important;
	height:auto !important;
}
@media all and (max-width:1024px) {
	#content {
		padding-left:10px;
		padding-right:10px;
	}
}
@media all and (max-width:768px) {
	div.row {
		display:block;
	}
	div.cell {
		display:block;
		width:auto;
	}
}
/* ******************************************************************** */
/* top header	 														*/
/* ******************************************************************** */
header.top {
	height: 70px;
}
header.top .logo{
	margin:6.5px 0;
	vertical-align:bottom;
	display:inline-block;
}
header.top .right {
	position:absolute;
	right:0;
	top:0;
	line-height:70px;
	height: 70px;
}
header.top nav ul {
	list-style:none;
	margin:0;
	padding:0;
	display:inline-block;
	vertical-align:top;
	height:70px;
}
header.top nav li {
	display:inline-block;
	font-size:16px;
	position:relative;
}
header.top nav li.active,
header.top nav li:hover {
	background:#4195db url('../assets/menu-bg.gif');
	color:#fff;
}
header.top nav a {
	text-decoration:none;
	display: inline-block;
	padding: 0 14px;
}
header.top nav a.right-bleed {
	padding-right:3000px;
}
header.top nav ul ul{
	display:none;
	position:absolute;
	top:100%;
	left:0;
	color: #fff;
	background-color: #3b5998;
	height:auto;
	width:240px;
	margin-left:-30px;
	z-index:10;
}
header.top nav li li{
	display:block;
}
header.top nav li:hover ul{
	display:block;
}
header.top .phone {
	display:inline-block;
	padding-left:10px;
	font-size:26px;
	color:#fff;
	background-color:#3390dd;
	vertical-align:top;
}
header.top .phone img {
	vertical-align:middle;
}

header.top nav .menu-toggle {
    background: #666 url('../assets/menu-icon.png') center no-repeat;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    margin: 15px;
    padding: 8px;
    border:none;
    box-sizing: content-box;
    display: none;
}
@media all and (max-width:1024px) {
	header.top .phone {
		font-size:0;
	}
	header.top .phone img {
		padding-right:10px;
	}
}
@media all and (max-width:768px) {
	header.top nav ul {
		display:none;
		position: absolute;
		z-index: 100;
		background:#fff;
		top:100%;
		left:0;
		right:0;
		text-align:left;
		height:auto;
	}
	header.top nav li:hover ul {
		display:none;
	}
	header.top nav a {
		display:block;
	}
	header.top nav a {
		position:relative;
		z-index:1000;
	}
	header.top nav .expando {
		position:absolute;
		right:0;
		top:0;
		bottom:0;
		width:50px;
		background:grey url('../assets/menu-drop.png') no-repeat center center;
	}
	header.top nav .active .expando {
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	header.top nav ul ul {
		position:static;
		margin-left:0;
		width:auto;
	}
	header.top nav li {
		display:block;
	}
	header.top nav li.active > ul {
		display:block;
	}
	header.top nav .right {
		left: 0;
		text-align: right;
	}
	header.top nav.active .right > ul {
		display: block;
	}
	header.top nav {
		cursor:pointer;
		position:relative;
	}
    header.top nav .menu-toggle {
        display: inline-block
    };
}

/* ******************************************************************** */
/* splash	 															*/
/* ******************************************************************** */
#banner {
	background:url('../assets/banner.jpg') center;
	height:593px;
	position:relative;
}
#banner .parralax {
	background:url('../assets/banner.png') center;
	position:absolute;
	left:50%;
	margin-left:100px;
	bottom:-140px;
	width:563px;
	height:727px;
	/*transition:all 100ms;
	-webkit-transition:all 50ms;*/
}
#banner .caption {
	text-align:center;
	position:absolute;
	left:0;
	right:0;
	top:0;
	bottom:0;
	color:#fff;
	font-size:104px;
	line-height:104px;
	text-shadow: 5px 5px 5px rgba(0,0,0,0.75);
	filter: dropshadow(color=#AA000000, offx=5, offy=5);
}
#banner .caption img {
	max-width:66%;
}
#banner .caption:after,
#banner .caption .text{ 
	display:inline-block;
	vertical-align:middle;
}
#banner .caption:after {
	content:"";
	width:0;
	height:100%;
}
#services {
	background-image:url('../assets/services-bg.gif');
	padding-bottom:40px;
	position: relative;
}
#services > header {
	text-align:center;
	background-color:#3390dd;
	color:#fff;
	font-size:34px;
	position:relative;
	margin-bottom:100px;
	padding:15px 0;
}
/*#services > header:after {
	content: "";
	position:absolute;
	display:block;
	top:100%;
	left:50%;
	margin-left:-30px;
	border-top:30px solid #3390dd;
	border-left:30px solid transparent;
	border-right:30px solid transparent;
}*/
#services .item {
	display:inline-block;
	vertical-align:top;
	font-size:38px;
	color:#3390dd;
	width:255px;
	text-align:center;
	line-height:1;
	text-decoration:none;
}
#news {
	position:relative;
	background: #303030 url('../assets/footer-bg.gif');
	color:#fff;
	padding-top:40px;
	padding-bottom:40px;
    .loader {
        width:16px;
        height: 16px;
        background: url('../assets/loader.gif') no-repeat;
        display: inline-block;
    }
    &:before,
    &:after {
        content:"";
        bottom:100%;
        height:0;
        background:#131313;
        position:absolute;
    }
    .item {
        display:inline-block;
        vertical-align:top;
        max-width:230px;
        height:500px;
        position:relative;
    }
    .item footer {
        margin-top:14px;
        border-top:1px solid #3390dd;
        color:#3390dd;
        position:absolute;
        font-size:16px;
        bottom:0;
        left:0;
        right:0;
    }
    .item footer .right {
        position:absolute;
        right:0;
    }
    header {
        position:absolute;
        bottom:310px;
        left:0;
        right:0;
    }
    section {
        font-family:Tahoma, Geneva, sans-serif;
        margin-top:196px;
        left:0;
        right:0;
        margin-bottom:39px;
    }
    .prev,
    .next {
        cursor:pointer;
        top:50%;
        left:50%;
        height:54px;
        margin-top:-27px;
        position:absolute;
        width:31px;
        display:none;
    }
    .prev {
        margin-left:-551px;
        background:url('../assets/prev.png');
    }
    .next {
        margin-left:520px;
        background:url('../assets/next.png');
    }
}
#team {
	position:relative;
	height:0;
	padding-bottom:31.45%;
	background-size: cover;
}
#team .body {
	background-position:center;
	top:0;bottom:0;
	left:0;right:0;
	position:absolute;
	background-size:cover;
}
#team h1 {
	font-size:96px;
	position:absolute;
	bottom:30px;
	margin:0;
	left:0;
	right:0;
	text-align:center;
}
#team a {
	position:absolute;
	height:37px;
	color:#3390dd;
	font-size:26px;
}
#team a.left {
	padding-right:40px;
	background:url('../assets/icon-plus.png') right center no-repeat;
	text-align:right;
}
#team a.right {
	padding-left:40px;
	background:url('../assets/icon-plus.png') left center no-repeat;
}
#team a.left.noplus {
	padding-right:10px;
	background:none;
}
#team a.right.noplus {
	padding-left:10px;
	background:none;
}

@media all and (max-width:1200px) {
	#team a {
		font-size:18px;
		height:29px;
	}
	#team a.left {
		background-size:30px;
		padding-right:35px;
	}
	#team a.right {
		background-size:30px;
		padding-left:35px;
	}
	#banner {
		height:500px;
	}
	
	#banner .parralax {
		width:474px;
		height:613px;
		bottom:-100px;
		background-size:cover;
	}
}
@media all and (max-width:1024px) {
	#banner {
		height:400px;
		background-size:cover;
	}
	
	#banner .parralax {
		width:380px;
		height:490px;
		bottom:-80px;
		margin-left:50px;
	}
	#banner .caption {
		font-size:72px;
	}
	#services .item {
		width:245px;
	}
	#news {
		padding:0 10px;
	}
	#team a {
		font-size:14px;
		height:25px;
	}
	#team a.left {
		background-size:20px;
		padding-right:25px;
	}
	#team a.right {
		background-size:20px;
		padding-left:25px;
	}
	#team h1 {
		font-size:76px;
	}
	#news header {
		bottom:290px;
	}
}
@media all and (max-width:550px) {
	#services .item {
		font-size:28px;
	}
}
@media all and (max-width:768px) {
	#banner .parralax {
		width:380px;
		height:490px;
		bottom:-80px;
		margin-left:-160px;
	}
	#services .item,
	#news .item {
		display:block;
		width:auto;
		margin-bottom:30px;
		max-width:none;
		height:auto;
	}
	#team {
		display:none;
	}
	
	#news header {
		position:static;
		bottom:auto;
	}
	#news section {
		position:static;
		top:auto;
		bottom:auto;
		margin-top:auto;
	}
	#news .item footer {
		position:relative;
		bottom:auto;
	}
	#banner .caption {
		font-size:48px;
		line-height:60px;
	}
	#services img {
		max-width:50%;
		height:auto;
	}
}
/* ******************************************************************** */
/* social bar	 														*/
/* ******************************************************************** */
.social {
	margin-top:-30px;
	font-size:40px;
}
.social .contentwrap {
	width:1024px;
}
.social .left, .social .right{
	width:50%;
	display:inline-block;
	color:#fff;
	vertical-align:top;
	height:100%;
	line-height:64px;
}
.social .right:after,
.social .left:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	width: 9999px;   /* some huge width */
}
.social .left:before {
	right:100%;
	background-color:#3390dd;
}
.social .left .body {
	padding:30px;
	background-color:#3390dd;
	margin-right:30px;
	position:relative;
	padding-right:0;
}
.social .left .body:before {
	content:"";
	position:absolute;
	width:0;
	height:50%;
	left:100%;
	border-top:30px solid transparent;
	border-left:30px solid #3390dd;
	top:0;
}
.social .left .body:after {
	content:"";
	position:absolute;
	width:0;
	height:50%;
	left:100%;
	border-bottom:30px solid transparent;
	border-right:30px solid #3390dd;
	bottom:0;
	margin-bottom:-30px;
}
.social .right {
	text-decoration:none;
}
.social .right:after {
	left: 100%;
	background-color:#3b579d;
	background-image:url('../assets/logo-facebook.png');
	background-repeat:no-repeat;
	background-position:left center;
}
.social .right .body {
	padding:30px;
	background-color:#3b579d;
	margin-left:30px;
	position:relative;
	padding-left:0;
}
.social .right .body:before {
	content:"";
	position:absolute;
	width:0;
	height:50%;
	right:100%;
	border-top:30px solid transparent;
	border-right:30px solid #3b579d;
	top:0;
}
.social .right .body:after {
	content:"";
	position:absolute;
	width:0;
	height:50%;
	right:100%;
	border-bottom:30px solid transparent;
	border-left:30px solid #3b579d;
	bottom:0;
	margin-bottom:-30px;
}
.enews-subscribe {
	display:inline;
}
.enews-subscribe input[type='email'] {
	width:160px;
	height:50px;
	border:none;
	vertical-align:middle;
}
.enews-subscribe input[type='submit'] {
	color:transparent;
	border:none;
	background:url('../assets/btn-submit.png');
	width:40px;
	height:39px;
	cursor:pointer;
	margin-left:10px;
	vertical-align:middle;
}
@media all and (max-width:1024px) {
	.social {
		font-size:24px;
	}
	.enews-subscribe input[type='email'] {
		width:138px;
		height:40px;
	}
	.enews-subscribe input[type='submit'] {
		width:30px;
		height:30px;
		background-size:cover;
	}
}
@media all and (max-width:768px) {
	.social {
		font-size:48px;
	}
	.social .left, .social .right {
		display:block;
		width:auto;
		margin:0;
		padding:0;
		position:relative;
	}
	.social .left .body, .social .right .body {
		margin:0;
		padding:5px;
	}
	.social .right:after {
		left:auto;
		right:10px;
		width:64px;
		background-position:right center;
		background-size:contain;
	}
}
@media all and (max-width:640px) {
	.social {
		font-size:32px;
	}
}
@media all and (max-width:420px) {
	.social {
		font-size:18px;
	}
}
/* ******************************************************************** */
/* footer		 														*/
/* ******************************************************************** */
footer.bottom {
	background:#303030 url('../assets/footer-bg.gif');
	color:#fff;
	padding:30px;
	font-size:20px;
	line-height:2;
}
footer .gutter {
	position:relative;
}
footer.bottom a {
	text-decoration:none;
	width: 100%;
	display: inline-block;
}
footer.bottom ul {
	text-align:justify;
	list-style:none;
	margin:0;
	padding:0;
}
footer.bottom ul:after {
	content :"";
	width:100%;
	height:0;
	display: inline-block;
}
footer.bottom li {
	display:inline-block;
	vertical-align:top;
	min-width:15%;
}
footer.bottom li a {
	border-bottom:solid 1px #c2c2c2;
}
footer.bottom li li {
	display:block;
	font-size:14px;
	font-family:Tahoma, Geneva, sans-serif;
}
footer.bottom li li a {
	border-bottom:none;
}
footer .center {
	position:absolute;
	width:300px;
	text-align:center;
	left:50%;
	margin-left:-150px;
}
footer .center img {
	vertical-align:middle;
}
.bykeo {
	text-align:right;
	position:absolute;
	width:auto;
	right:0;
	top:0;
}
.bykeo a {
	text-decoration:none;
	color:#fff;
}
.bykeo img {
	vertical-align:middle;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.bykeo a:hover img {
	-moz-transform: rotate(360deg);
	-webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}
@media all and (max-width:960px) {
	footer .center,.bykeo {
		text-align:left;
		position:static;
		margin-left:0;
	}
	
}
/* ******************************************************************** */
/* bottom gallery 														*/
/* ******************************************************************** */
.bottom-gallery {
	position:relative;
	margin-top:30px;
}
.bottom-gallery-scroll {
	overflow:hidden;
}
.bottom-gallery-slide {
	width:auto;
	white-space:nowrap;
}
.bottom-gallery .prev,
.bottom-gallery .next {
	display:block;
	cursor:pointer;
	top:50%;
	left:50%;
	height:54px;
	margin-top:-27px;
	position:absolute;
	width:31px;
	display:none;
}
.bottom-gallery .prev {
	margin-left:-551px;
	background:url('../assets/prev.png');
}
.bottom-gallery .next {
	margin-left:520px;
	background:url('../assets/next.png');
}
#content .kmsgallery-thumbnail-image {
    width: 300px;
    height: 300px;
    margin: 0;
    img {
        width:300px;
        height:300px !important;
        object-fit: cover;
    }
}

/* ******************************************************************** */
/* news			 														*/
/* ******************************************************************** */
.news-image {
	float:right;
	margin:10px;
}



